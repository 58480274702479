.app {
  text-align: center;
}

.app-logo {
  height: 11vmin;
  pointer-events: none;
  margin-left: -1100px;
  background-image: 20px;
}

.app-header {
  min-height: 10vh;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  top: 10px;
  animation-name: fade;
  animation-duration: 3s;
  opacity: 1;
}

.app-heading {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  margin-left: -500px;
  margin-bottom: -40px;
  margin-top: 100px;
  font-size: 15px;
  color: rgba(52, 8, 109, 0.863);
}
.app-heading2 {
  margin: 60px 0 -60px -500px;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 30px;
  color: rgba(8, 43, 28, 0.795);
  animation-name: fade;
  animation-duration: 2s;
  opacity: 1;
}

.app-paragraph {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 17px;
  margin: 100px 600px 0 100px;
  text-align: center;
}

.img {
  margin-left: 1000px;
  margin-top: -180px;
  height: 200px;
}

@keyframes fade {
  0% {
    opacity: 0;
    top: 0;
  }
  50% {
    opacity: 0.1;
    top: 5px;
  }

  100% {
    opacity: 1;
    top: 10px;
  }
}
.flex-container {
  display: flex;
}

.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 10px;
  padding-top: 20px;
}

.nav-link {
  padding: 10px;
}

.nav-link a {
  font-weight: 900;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgba(96, 32, 116, 0.966);
  text-decoration: none;
}

.nav-link:visited a {
  color: rgba(17, 71, 55, 0.733);
}
.nav-link:hover a {
  color: rgba(17, 71, 55, 0.733);
}

.nav-link:active a {
  color: rgba(17, 71, 55, 0.733);
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      rgba(20, 2, 44, 0.753),
      rgba(60, 15, 109, 0.719),
      rgba(12, 88, 69, 0.74)
    ),
    url(https://i2.wp.com/onaircode.com/wp-content/uploads/2017/11/Constellation.jpg?w=1080&ssl=1);
  animation-name: fadeInUp;
  animation-duration: 3s;
  z-index: -1;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

.about {
  position: relative;
  top: 100px;
}
.app-link {
  font-family: Arial, sans-serif;
  text-decoration: none;
  color: rgb(107, 33, 124);
  font-weight: 550;
  font-size: 20px;
  border-radius: 7px;
  border-style: groove;
  border-color: rgba(57, 15, 92, 0.281);
  background-color: rgba(34, 31, 29, 0.685);
  padding: 3px 16px 3px 16px;
  box-shadow: 0 10px 20px rgba(122, 121, 122, 0.623),
    0 6px 6px rgba(122, 121, 122, 0.623);
  position: absolute;
  top: 5em;
}

.app-link:hover {
  transform: scale(1.05);
  color: rgba(190, 183, 183, 0.308);
  background-image: linear-gradient(
    to right,
    rgba(148, 16, 209, 0.473),
    rgba(13, 13, 121, 0.342)
  );
}

.app-link:active {
  /* background-color: rgba(94, 6, 177, 0.658); */
  transform: scale(0.9);
  color: rgba(190, 183, 183, 0.308);
  background-image: linear-gradient(
    to right,
    rgba(148, 16, 209, 0.473),
    rgba(13, 13, 121, 0.342)
  );
}
.aboutheader {
  color: rgba(187, 121, 170, 0.856);
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  left: 100px;
  right: 200px;
  top: 10px;
  text-align: center;
  animation-name: fade;
  animation-duration: 3s;
  opacity: 1;
}

.aboutheading {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 30px;
  margin-top: 100px;
  margin-left: 50px;
  margin-right: 100px;
}

.aboutparagraph {
  color: rgb(235, 231, 231);
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 17px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 120px;
  animation-name: fade;
  animation-duration: 2s;
  opacity: 1;
}
